/* eslint-disable max-len */
const general = {
    impression: 'Form & PopUp',
    landing: 'Landing Page',
    senderplus: 'Email Transazionali',
    email: 'Email',
    sms: 'SMS',
    phone: 'Telefono',
    phoneNumber: '+39 011.7653100',
    commercialEmail: 'commerciale@4dem.it',
    campaigns: 'Campagne',
    automations: 'Automations',
    automation: 'Automation',
    openRate: 'Open rate',
    clickRate: 'Click rate',
    close: 'Chiudi',
    save: 'Salva',
    confirm: 'Conferma',
    cancel: 'Annulla',
    delete: 'Elimina',
    go: 'Vai',
    privacy: 'Informativa Privacy',
    rate: {
        months: 'al mese | ogni {count} mesi',
        days: 'al giorno | ogni {count} giorni',
        years: 'all\'anno | ogni {count} anni',
    },
    rateSlim: {
        months: 'mese | {count} mesi',
        days: 'giorno | {count} giorni',
        years: 'anno | {count} anni',
    },
    time: {
        hours: '{count} ora | {count} ore',
        minutes: '{count} minuto | {count} minuti',
        seconds: '{count} secondo | {count} secondi',
    },
    lessThanOneSecond: 'Meno di 1 secondo',
    orderBy: {
        createdAtDesc: 'Più recenti',
        createdAtAsc: 'Meno recenti',
    },
    copyClip: 'Copiato negli appunti',
    copyClipError: 'Errore durante la copia negli appunti',
    found: '{count} trovato | {count} trovati',
}
const header = {
    taxable: 'Imponibile',
    discount: 'Sconto',
    vat: 'IVA',
    total: 'Totale',
    completePurchase: 'Concludi l\'acquisto',
    emptyTheCart: 'Svuota il carrello',
    emptyCart: 'IL TUO CARRELLO È VUOTO',
    tightPlan: 'Il tuo piano ti sta stretto?',
    findPlan: 'Scopri i piani',
    loginToConsole: 'Accedi alla console',
    yourAccount: 'Il mio account',
    yourPlan: 'Il mio piano',
    prices: 'Prezzi',
    yourPurchase: 'Storico acquisti',
    yourData: 'Dati di fatturazione',
    settings: 'Impostazioni',
    exit: 'Esci',
    confirmExit: 'Vuoi davvero uscire?',
    abort: 'Annulla',
}
const topnav = {
    search: 'Cerca...',
    searching: 'RICERCA IN CORSO...',
    threeDigits: 'digita almeno 3 caratteri',
    help: {
        resources: 'RISORSE',
        developers: 'Portale sviluppatori',
        university: 'Centro formazione',
        desk: 'HELP DESK',
        support: 'Richiedi Supporto',
    },
}
const sidebar = {
    createCampaign: 'Crea campagna',
    sections: {
        comunicate: 'Comunica',
        grow: 'Cresci',
        audience: 'Audience',
        creativity: 'Creatività',
        mocks: 'Mocks',
    },
    subsections: {
        campaigns: 'Campagne',
        automations: 'Marketing automation',
        transactionalEmail: 'Email transazionali',
        transactionalSms: 'Sms transazionali',
        forms: 'Form e Popup',
        landings: 'Landing Page',
        integrations: 'Integrazioni e-commerce',
        lists: 'Liste',
        tags: 'Tag comportamentali',
        suppression: 'Lista di soppressione',
        medialibrary: 'Media Library',
        models: 'I miei modelli email',
        emailFooters: 'Footer Email',
        mocksList: 'Elenco mocks',
        whatsapp: 'WhatsApp',
        senders: 'Mittenti',
    },
    reputation: {
        title: 'Reputazione',
        humanReputation: {
            bad: 'Negativa',
            best: 'Positiva',
            normal: 'Normale',
        },
    },
    avatarLinks: {
        dashboard: 'Dashboard',
        plan: 'Piano',
        account: 'Account',
        prices: 'Prezzi',
        accessLog: 'Log accessi',
        manager: 'Area manager',
        logout: 'Logout',
    },
    settings: 'Impostazioni',
}
const mainFooter = {
    description: 'Advision Srl UNIPERSONALE | Corso San Martino 1, 10122 Torino | Tel: {0} | {1} | Piva: 10351260012 | CAP. SOCIALE: 30.000€ | Ufficio del Registro delle Imprese di Torino | REA: TO-1125920 | {2}',
}

const createSender = {
    contactAddress: 'Indirizzo email di contatto',
    emailAddress: 'Indirizzo email',
    address: 'Indirizzo',
    city: 'Città',
    province: 'Provincia',
    country: 'Nazione',
    cap: 'Cap',
    mobile: 'Telefono',
    header: 'Crea mittente autorizzato',
    senderData: 'Dati mittente',
    senderDataSubtitle: 'Compila i dati che verranno visualizzati nella casella di posta del destinatario.',
    csaData: 'Dati mittente csa',
    csaDataSubtitle: 'Compila i dati che verranno utilizzati per popolare le informazioni del mittente in ogni email.',
    nominative: 'Nominativo',
    businessName: 'Ragione sociale o Nome e Cognome',
    fiscalCode: 'Partita IVA o Codice fiscale',
    tips: {
        businessName: 'Inserisci la ragione sociale o il nome e cognome (nel caso di persone fisiche) di questo mittente. Questo dato può anche differire dal nominativo associato al mittente utilizzato per gli invii delle tue email. Il dato verrà mostrato ai tuoi destinatari nel footer delle tue email.',
        fiscalCode: 'Inserisci la partita IVA o il codice fiscale del responsabile di questo mittente. Il dato verrà mostrato ai tuoi destinatari nel footer delle tue email.',
        address: 'Inserisci l\'indirizzo dove è stabilito il mailer, ovvero il mittente delle email.',
        mobile: 'Inserisci un recapito telefonico (comprensivo di prefisso internazionale) dove il responsabile di questo mittente potrà essere contattato in caso di necessità su questioni di privacy. Il dato verrà mostrato ai tuoi destinatari nel footer delle tue email.',
        email: 'Inserisci un indirizzo email funzionante dove il responsabile del mittente potrà essere contattato in caso di necessità su questioni di privacy. L\'indirizzo email può coincidere con quello mittente o impostato nel reply to. Il dato verrà mostrato ai tuoi destinatari nel footer delle tue email.',
    },
    success: 'Mittente creato con successo',
}

const createTagModal = {
    title: 'Crea un nuovo TAG',
    label: 'Inserisci il nome del TAG che vuoi creare',
    placeholder: 'Inserisci qui il nome del Tag',
    close: 'Annulla',
    save: 'Salva',
}

const addOrEditAddressbookModal = {
    title: 'Crea nuovo contatto preferito',
    close: 'Chiudi',
    save: 'Salva',
    emailLabel: 'Indirizzo email',
    footerLabel: 'Footer personalizzato',
    footerPlaceholder: 'Nessun footer',
    emailPlaceholder: 'Inserisci un indirizzo email',
    createError: 'Errore durante la creazione del nuovo contatto preferito',
}

const supportEmail = {
    title: 'Richiesta supporto',
    close: 'Chiudi',
    save: 'Invia',
    subjectLabel: 'Inserisci l\'oggetto del messaggio',
    messageLabel: 'Inserisci il messaggio',
}

const selectListPopUp = {
    title: 'Seleziona una lista',
    placeholder: 'Seleziona una lista',
    name: 'Nome lista',
    nolists: 'Non hai ancora creato una lista',
    createList: 'Crea lista',
}

const route = {
    // signup module
    signup: 'Registrazione',
    registration: 'Registrazione',
    activate: 'Registrazione',
    // shop module
    shop: 'Shop',
    shopIndex: 'Shop',
    operationComplete: 'Shop',
    operationFailed: 'Shop',
    checkout: 'Shop',
    // settings module
    settings: 'Impostazioni',
    settingsIndex: 'Impostazioni',
    generalSettings: 'Impostazioni',
    notificationsSettings: 'Impostazioni',
    contactsAndSendersSettings: 'Impostazioni',
    developersSettings: 'Impostazioni',
    // login module
    login: 'Login',
    loginIndex: 'Login',
    reset: 'Login',
    // lists module
    lists: 'Liste',
    listsIndex: 'Liste',
    listCreate: 'Crea lista',
    createContact: 'Crea contatto',
    listIdIndex: 'Lista',
    listEdit: 'Lista',
    listCustomFields: 'Lista',
    // landingpages module
    landingpages: 'Landing Page',
    PagesCards: 'Landing Page',
    CreatePage: 'Landing Page',
    EditPage: 'Landing Page',
    EditPageProject: 'Landing Page',
    Subdomains: 'Landing Page',
    // dashboard module
    dashboard: 'Dashboard',
    dashboardIndex: 'Dashboard',
    // campaign module
    campaigns: 'Campagne',
    campaignsIndex: 'Campagne',
    heatmap: 'Mappa di calore',
    // activation module
    activation: 'Attivazione',
    activationIndex: 'Attivazione',
    // account module
    plan: 'Il mio piano',
    planIndex: 'Il mio piano',
    myPlan: 'Il mio piano',
    invoiceData: 'Dati di fatturazione',
    payments: 'Storico acquisti',
    account: 'Account',
    accountIndex: 'Account',
    myAccount: 'Account',
    security: 'Sicurezza',
    communication: 'Comunicazioni',
    prices: 'Prezzi',
    pricesIndex: 'Prezzi',
    rechargeablePrices: 'Prezzi',
    creditsSms: 'Prezzi',
    creditsTransactional: 'Prezzi',
}

export default {
    general,
    header,
    topnav,
    sidebar,
    mainFooter,
    createSender,
    createTagModal,
    addOrEditAddressbookModal,
    supportEmail,
    selectListPopUp,
    route,
}
